<template>
  <div class="h">
    <div
      :class="slideIn"
      class="p-8 xl:px-24 fixed top-0 left-0 bg-white border-r border-border w-80 h-screen"
    >
      <div class="flex items-center justify-between">
        <a href="/" class="cursor-pointer">
          <img src="/assets/images/LoanSpot-logo.svg" alt="" class="" />
        </a>
        <span @click="closeMobileMenu()">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2.92871"
              y="0.100525"
              width="24"
              height="3"
              rx="1"
              transform="rotate(45 2.92871 0.100525)"
              fill="#1656C1"
            />
            <rect
              x="19.1924"
              y="2.2218"
              width="24"
              height="3"
              rx="1"
              transform="rotate(135 19.1924 2.2218)"
              fill="#1656C1"
            />
          </svg>
        </span>
      </div>
      <div class="flex flex-col auth-wrap">
        <div class="flex items-center">
          <span class="pr-4 hidden">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M10.0002 0.833313C15.0628 0.833313 19.1668 4.93737 19.1668 9.99998C19.1668 15.0626 15.0628 19.1666 10.0002 19.1666C4.93755 19.1666 0.833496 15.0626 0.833496 9.99998C0.833496 4.93737 4.93755 0.833313 10.0002 0.833313ZM2.55489 9.08949C2.79784 9.40044 3.47336 9.7983 4.50705 10.1229C4.91406 10.2507 5.35983 10.3631 5.83812 10.4585C5.83505 10.3065 5.8335 10.1537 5.8335 9.99998C5.8335 7.22694 6.33813 4.72273 7.21229 3.03523C4.72027 4.03367 2.88873 6.33082 2.55489 9.08949ZM5.94111 12.1757C4.60295 11.9431 3.45555 11.5911 2.58599 11.1381C2.99049 13.7951 4.78915 15.9939 7.21228 16.9647C6.57405 15.7326 6.1328 14.0652 5.94111 12.1757ZM7.64927 12.3978C8.39685 12.4651 9.18424 12.5 10.0002 12.5C10.8029 12.5 11.5939 12.4545 12.3546 12.3701C11.9787 15.3681 10.9393 17.5 10.0002 17.5C9.06391 17.5 8.02807 15.3812 7.64927 12.3978ZM12.4888 10.6704C11.6975 10.7729 10.8569 10.8333 10.0002 10.8333C9.12998 10.8333 8.29468 10.7902 7.51294 10.7085C7.50453 10.4754 7.50018 10.2391 7.50018 9.99997C7.50018 5.81997 8.82818 2.49997 10.0002 2.49997C11.1722 2.49997 12.5002 5.81997 12.5002 9.99997C12.5002 10.2261 12.4963 10.4497 12.4888 10.6704ZM14.0662 12.1057C13.8784 14.0241 13.4341 15.7175 12.788 16.9647C15.254 15.9768 17.0732 13.7171 17.4345 10.997C16.551 11.4565 15.3874 11.8408 14.0662 12.1057ZM17.4406 9.05008C17.0485 9.49024 15.8116 10.0131 14.1637 10.3784C14.1658 10.2529 14.1668 10.1267 14.1668 9.99998C14.1668 7.22694 13.6622 4.72271 12.788 3.03522C15.2682 4.02888 17.0941 6.30893 17.4406 9.05008Z"
                fill="#646F79"
              />
            </svg>
          </span>
        </div>
        <div class="mt-8">
          <div class="pb-3 mt-3 border-b border-border">
            <span
              @click.prevent="searchLoanForm = true"
              class="font-bold bg-accent-2 h-10 rounded text-primary flex items-center justify-center cursor-pointer"
            >
              Search for loans
            </span>
          </div>
          <router-link :to="dashboard.overview" class="text-primary py-4 block"
            >Overview</router-link
          >
          <router-link :to="dashboard.history" class="text-primary py-4 block"
            >History</router-link
          >
          <router-link :to="dashboard.settings" class="text-primary py-4 block"
            >Settings</router-link
          >
          <router-link :to="dashboard.help" class="text-primary py-4 block"
            >Help</router-link
          >
          <span
            @click.prevent="logoutUser"
            class="text-primary py-4 block cursor-pointer"
            >Logout</span
          >
        </div>
      </div>
    </div>
    <nav class="bg-white px-4 py-5 md:p-8 lg:p-0 border-b border-border">
      <div class="flex items-center justify-between w-full text-xl lg:hidden">
        <a href="/" class="cursor-pointer">
          <img src="/assets/images/LoanSpot-logo.svg" alt="" class="" />
        </a>
        <div class="flex items-center">
          <div
            v-if="profile.user && profile.loaded"
            class="flex items-center justify-center text-white rounded min-w-12 h-12 bg-primary"
          >
            <span
              v-if="profile.user.profileImage !== null"
              class="bg-white border border-input rounded"
            >
              <img
                :src="profile.user.profileImage"
                alt=""
                class="object-cover rounded w-12 h-12"
              />
            </span>
            <span v-else class="text-white font-family-bold">
              {{ profile.user.firstName[0] }}{{ profile.user.lastName[0] }}
            </span>
          </div>
          <span @click="openMobileMenu()" :class="hideHamburger" class="ml-2">
            <svg
              width="24"
              height="17"
              viewBox="0 0 24 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="24" height="3" rx="1" fill="#1656C1" />
              <rect y="7" width="24" height="3" rx="1" fill="#1656C1" />
              <rect y="14" width="24" height="3" rx="1" fill="#1656C1" />
            </svg>
          </span>
        </div>
      </div>
      <div
        ref="navWrap"
        id="navWrap"
        class="fixed top-0 left-0 right-0 w-full bg-white border-b border-border"
      >
        <div
          class="px-8 py-6 xl:px-24 bg-white lg:items-center lg:justify-between hidden lg:flex lg:flex-row flex-col xxl:max-w-screen-xxl xxl:m-auto 2xl:px-0"
        >
          <div class="logo-wrap flex items-center justify-between text-xl">
            <a href="/" class="cursor-pointer">
              <img src="/assets/images/LoanSpot-logo.svg" alt="" class="" />
            </a>
          </div>
          <div class="flex items-center">
            <span
              @click.prevent="searchLoanForm = true"
              class="text-primary text-sm block hover:underline cursor-pointer"
              >Search for Loans</span>
            <router-link :to="dashboard.overview">
              <span
                :class="this.$route.path === '/' ? 'title' : ''"
                class="text-primary text-sm ml-8 block hover:underline cursor-pointer"
                >Overview</span
              >
            </router-link>
            <router-link :to="dashboard.history">
              <span
                :class="this.$route.path === '/history' ? 'title' : ''"
                class="text-primary text-sm ml-8 block hover:underline cursor-pointer"
                >History</span
              >
            </router-link>
            <a :href="dashboard.settings">
              <span
                :class="this.$route.path.includes('/settings') ? 'title' : ''"
                class="text-primary text-sm ml-8 block hover:underline cursor-pointer"
                >Settings</span
              >
            </a>
            <div
              @click="logout = !logout"
              class="flex items-center justify-between cursor-pointer ml-8"
              v-if="profile.user && profile.loaded"
            >
              <div class="flex items-center cursor-pointer">
                <div
                  class="flex items-center justify-center text-white rounded w-12 h-12 bg-primary ml-4 font-family-bold"
                >
                  <span
                    v-if="profile.user.profileImage !== null"
                    class="bg-white border border-input rounded"
                  >
                    <img
                      :src="profile.user.profileImage"
                      alt=""
                      class="object-cover rounded w-12 h-12"
                    />
                  </span>
                  <span v-else class="text-white font-family-bold">
                    {{ profile.user.firstName[0]
                    }}{{ profile.user.lastName[0] }}
                  </span>
                </div>
              </div>
              <div class="ml-4">
                <svg
                  width="12"
                  height="11"
                  viewBox="0 0 18 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.00009 7.65704L1.92898 0.585938L0.514771 2.00015L7.58588 9.07126L7.58584 9.07129L9.00006 10.4855L17.4853 2.00022L16.0711 0.586008L9.00009 7.65704Z"
                    fill="#323643"
                  />
                </svg>
              </div>
              <div
                :class="logout ? '' : 'hidden'"
                class="absolute top-24 rounded bg-white border border-input"
              >
                <router-link :to="dashboard.help">
                  <span
                    class="text-primary py-2 text-sm pl-3 pr-20 block hover:bg-accent-2"
                    >Help</span
                  >
                </router-link>
                <span
                  @click.prevent="logoutUser"
                  class="text-primary py-2 text-sm px-3 block hover:bg-accent-2"
                  >Logout</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div
      v-if="searchLoanForm"
      @click.self="searchLoanForm = false"
      class="fixed bg-search-bg top-0 h-screen w-full flex justify-center overflow-y-scroll px-4"
    >
      <div
        class="p-4 mt-28 bg-white w-96 rounded-lg border border-border h-min"
        id="form-modal"
      >
        <form @submit.prevent="submitSearch" id="search-form">
          <h4 class="text-lg title">Search for loans</h4>
          <div class="amount-wrap mt-4">
            <label for="" class="input-label"
              >How much do you want to borrow?</label
            >
            <div class="input-wrap" id="borrow-input-wrap">
              <input
                type="text"
                class="input"
                id="borrow-amount-input"
                autocomplete="off"
                name="amount"
                v-model="search.amount"
                maxlength="10"
              />
              <div class="input--currency-wrap">
                <select
                  name="loanCurrency"
                  id="loanCurrency"
                  class="input--currency"
                >
                  <option value="NGN">NGN</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
          </div>
          <div class="income-wrap">
            <label for="" class="input-label"
              >What is your net monthly income?</label
            >
            <div class="input-wrap" id="income-input-wrap">
              <input
                type="text"
                class="input"
                id="income-amount-input"
                autocomplete="off"
                name="income"
                v-model="search.income"
                maxlength="10"
              />
              <div class="input--currency-wrap">
                <select
                  name="incomeCurrency"
                  id="incomeCurrency"
                  class="input--currency"
                >
                  <option value="NGN">NGN</option>
                  <option value="USD">USD</option>
                </select>
              </div>
            </div>
          </div>
          <div class="loan-type-wrap">
            <span class="input-label">Type of loan</span>
            <div class="loan-type-radio">
              <div>
                <label
                  for="loan-type-business"
                  id="type-business"
                  class="input-wrap type-business"
                  :class="loanTypeBusiness ? 'radio-type--selected' : ''"
                  @click="setLoanBusiness"
                  >Business</label
                >
                <!-- v-model="loanTypeBusiness" -->
                <input
                  name="loanType"
                  type="radio"
                  id="loan-type-business"
                  class="input"
                  value="business"
                />
              </div>
              <div>
                <label
                  for="loan-type-individual"
                  id="type-individual"
                  class="input-wrap type-individual"
                  :class="loanTypeIndividual ? 'radio-type--selected' : ''"
                  @click="setLoanIndividual"
                  >Individual</label
                >
                <!-- v-model="loanTypeIndividual" -->
                <input
                  name="loanType"
                  type="radio"
                  id="loan-type-individual"
                  class="input"
                  value="individual"
                />
              </div>
            </div>
          </div>
          <div class="purpose-wrap">
            <label for="" class="input-label"
              >What is the purpose of this loan?</label
            >
            <div class="input-wrap input-wrap--purpose">
              <select
                name=""
                id=""
                :class="loanTypeBusiness || loanTypeIndividual ? 'hidden' : ''"
                class="input-purpose bg-transparent"
              >
                <option>Select type of loan</option>
              </select>
              <select
                v-if="loanTypeBusiness"
                name="loanPurpose"
                id="input--purpose"
                class="input--purpose"
              >
                <option
                  v-for="business in dropdownOptions.business"
                  :key="business.id"
                  :value="business.id"
                >
                  {{ business.title }}
                </option>
              </select>
              <select
                v-else-if="loanTypeIndividual"
                name="loanPurpose"
                id="input--purpose"
                class="input--purpose"
              >
                <option
                  v-for="individual in dropdownOptions.individual"
                  :key="individual.id"
                  :value="individual.id"
                >
                  {{ individual.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="existng-loan-wrap">
            <span class="input-label">Existing loan</span>
            <div class="existing-loan-radio">
              <div>
                <label
                  for="loan-yes"
                  id=""
                  class="input-wrap existing-yes"
                  :class="existingLoanYes ? 'radio-type--selected' : ''"
                  >Yes</label
                >
                <input
                  name="existingLoan"
                  type="radio"
                  id="loan-yes"
                  class="input"
                  value="yes"
                  @click="setExistingLoanYes"
                />
              </div>
              <div>
                <label
                  for="loan-no"
                  id=""
                  class="input-wrap existing-no"
                  :class="existingLoanNo ? 'radio-type--selected' : ''"
                  >No</label
                >
                <input
                  name="existingLoan"
                  type="radio"
                  id="loan-no"
                  class="input"
                  value="no"
                  @click="setExistingLoanNo"
                />
              </div>
            </div>
          </div>
          <button id="search-btn" class="form-btn">Search</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import { DASHBOARD_ROUTES } from "../../constants";
import { BASE_API } from "../../constants";

export default {
  name: "Dashboard-Navbar",
  components: {},
  mounted() {
    this.fetchLoanTypes();
    this.fetchLoanPurposes();
  },
  computed: {
    ...mapState({
      profile: (state) => state.profile,
    }),
    amount() {
      return this.search.amount;
    },
    income() {
      return this.search.income;
    },
  },
  watch: {
    amount(event) {
      if (event.key >= 37 && event.key <= 40) return;
      this.search.amount = this.search.amount
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    income(event) {
      if (event.key >= 37 && event.key <= 40) return;
      this.search.income = this.search.income
        .replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  data() {
    return {
      searchLoanForm: false,
      search: {
        amount: "",
        income: "",
      },
      dropdownOptions: {},
      loanTypes: [],
      loanTypeBusiness: false,
      loanTypeIndividual: false,
      existingLoanYes: false,
      existingLoanNo: false,
      slideIn: "hidden",
      hideHamburger: "",
      logout: false,
      dashboard: {
        overview: DASHBOARD_ROUTES.OVERVIEW,
        history: DASHBOARD_ROUTES.HISTORY,
        settings: DASHBOARD_ROUTES.SETTINGS.PERSONAL,
        help: DASHBOARD_ROUTES.HELP,
      },
    };
  },
  methods: {
    logoutUser() {
      localStorage.clear();
      this.$router.replace('/login');
    },
    openMobileMenu() {
      this.slideIn = "animate-fast-slide";
      this.hideHamburger = "hidden";
    },
    closeMobileMenu() {
      this.slideIn = "hidden";
      this.hideHamburger = "";
    },
    async fetchLoanPurposes() {
      try {
        const response = await (
          await fetch(BASE_API + "/loan-purposes")
        ).json();
        this.dropdownOptions.business = response.data.filter(
          (p) => p.loanType.slug === "business"
        );
        this.dropdownOptions.individual = response.data.filter(
          (p) => p.loanType.slug === "individual"
        );
      } catch (error) {
        // console.log(error);
      }
    },
    async fetchLoanTypes() {
      try {
        const response = await (await fetch(BASE_API + "/loan-types")).json();
        this.loanTypes = response.data;
      } catch (error) {
        // console.log(error);
      }
    },
    setLoanBusiness() {
      this.loanTypeBusiness = true;
      this.loanTypeIndividual = false;
    },
    setLoanIndividual() {
      this.loanTypeIndividual = true;
      this.loanTypeBusiness = false;
    },
    setExistingLoanYes() {
      this.existingLoanYes = true;
      this.existingLoanNo = false;
    },
    setExistingLoanNo() {
      this.existingLoanNo = true;
      this.existingLoanYes = false;
    },
    submitSearch(e) {
      if (this.search.amount === "") {
        return this.$notie.alert({
          text: `Please input an amount to borrow`,
          type: "error",
          time: 3,
          position: "top",
        });
      }
      if (this.search.income === "") {
        return this.$notie.alert({
          text: `Please input your net monthly income`,
          type: "error",
          time: 3,
          position: "top",
        });
      }

      const loanTypeInputs = document.getElementsByName("loan-type");
      let loanTypeValue;

      for (let i = 0; i < loanTypeInputs.length; i++) {
        if (loanTypeInputs[i].checked) {
          loanTypeValue = loanTypeInputs[i].value;
          return loanTypeValue;
        }
      }

      const params = {
        page: 1,
        limit: 10,
      };

      for (const [key, value] of new FormData(e.target).entries()) {
        console.log(key, value);
        if (["amount", "income"].includes(key)) {
          params[key] = value.replace(/,/gi, "");
        } else if (key === "loanType") {
          const type = this.loanTypes.find((t) => t.slug === value);
          if (type) {
            params[key] = type.id;
          }
        } else params[key] = value;
      }

      const query = new URLSearchParams(params).toString();
      const url = "/search?" + query;
      // console.log(params);
      // console.log(url);
      window.open(url, "_self");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: SohneKraftig, sans-serif;
}
.input-label {
  font-family: SohneKraftig, sans-serif;
  font-size: 0.75rem;
  color: #575a65;
}

.input-wrap {
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #f9fafc;
  border-radius: 8px;

  .input {
    width: 100%;
    height: inherit;
    border-radius: 8px;
    border-top: 1px solid #e5e9f2;
    border-bottom: 1px solid #e5e9f2;
    border-left: 1px solid #e5e9f2;
    border-right: none;
    color: #323643;
    padding: 0 16px;
    font-family: SohneKraftig, sans-serif;
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-color: transparent;

    &:focus,
    &:active {
      outline: none;
      border: 1px solid #1656c1;
    }
  }

  .input--currency-wrap {
    height: 48px;
    width: 72px;
    padding: 0 8px 0 8px;
    box-sizing: border-box;
    background-color: #1656c1;
    border: 1px solid #1656c1;
    border-radius: 0 8px 8px 0;
    margin-left: -72px;

    .input--currency {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      height: 100%;
      width: 100%;
      background-color: #1656c1;
      font-size: 0.875rem;
      border: none;
      color: #ffffff;
      padding: 0;
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.income-wrap,
.loan-type-wrap,
.purpose-wrap,
.existng-loan-wrap {
  margin-top: 16px;
}

.loan-type-radio,
.existing-loan-radio {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  .input-wrap {
    border: 1px solid #e5e9f2;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    color: #575a65;
    cursor: pointer;

    &:hover {
      border: 1px solid #1656c1;
      color: #1656c1;
      font-family: SohneKraftig, sans-serif;
    }
  }

  .input {
    display: none;
  }
}

.radio-type--selected {
  border: 1px solid #1656c1 !important;
  color: #1656c1 !important;
  font-family: SohneKraftig, sans-serif;
}

.input-wrap--purpose {
  padding-right: 12px;
  border: 1px solid #e5e9f2;
  cursor: pointer;
  .input--purpose {
    width: 100%;
    height: inherit;
    border-radius: 8px;
    background-color: transparent;
    padding: 0 16px;
    border: none;
    color: #575a65;

    &:focus,
    &:active {
      outline: none;
      // border: 1px solid $primary-color;
    }
  }
  select {
    color: #575a65;
    font-size: 0.875rem;
  }
}

.form-btn {
  font-family: SohneKraftig, sans-serif;
  color: #ffffff;
  background-color: #1656c1;
  border-radius: 8px;
  border: none;
  outline: none;
  width: 100%;
  height: 56px;
  margin-top: 32px;
  cursor: pointer;

  &:focus,
  &:active {
    outline: none;
  }
}
</style>
